import React, { Fragment } from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const MedcertTitle = ({ activeMedcert, isAdmin }) => (
  <Fragment>
    <Link to={isAdmin ? "/admin/medcerts" : "/medcerts"}>
      <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
    </Link>
    View Medical Certificate (Issued{" "}
    {moment(activeMedcert.dateIssued).format("MMMM D, YYYY")})
  </Fragment>
);

export default MedcertTitle;
