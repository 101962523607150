import React from "react";
import styles from "../medcerts.module.scss";
import classNames from "classnames";
import moment from "moment";
import { MEDCERT_INFORMATION } from "./headers";
import MedcertTableRow from "./MedcertTableRow";

const MedcertInfoTable = ({ values, receivedBy }) => {
  return (
    <table className={classNames("table is-fullwidth", styles.isHalfColumn)}>
      {Object.keys(MEDCERT_INFORMATION).map(key => (
        <MedcertTableRow
          index={key}
          header={MEDCERT_INFORMATION[key]}
          value={values[key]}
        >
          {key === "dateIssued" || key === "returnBy" || key === "dateReceived"
            ? moment(values[key]).format("MMMM D, YYYY")
            : key === "receivedBy"
            ? `${receivedBy}`
            : values[key]}
        </MedcertTableRow>
      ))}
    </table>
  );
};

export default MedcertInfoTable;
