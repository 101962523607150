import React from "react";
import styles from "../medcerts.module.scss";
import classNames from "classnames";
import { MEDCERT_STATUS } from "./headers";
import MedcertTableRow from "./MedcertTableRow";

const MedcertStatusTable = ({ values }) => (
  <table className={classNames("table is-fullwidth", styles.isHalfColumn)}>
    {Object.keys(MEDCERT_STATUS).map(key => (
      <MedcertTableRow
        index={key}
        header={MEDCERT_STATUS[key]}
        value={values[key]}
      >
        {values[key]}
      </MedcertTableRow>
    ))}
  </table>
);

export default MedcertStatusTable;
