import React from "react";
import styles from "../medcerts.module.scss";
import classNames from "classnames";
import { HOSPITAL_INFORMATION } from "./headers";
import MedcertTableRow from "./MedcertTableRow";

const HospitalInfoTable = ({ values }) => (
  <table className={classNames("table is-fullwidth", styles.isHalfColumn)}>
    {Object.keys(HOSPITAL_INFORMATION).map(key => (
      <MedcertTableRow
        index={key}
        header={HOSPITAL_INFORMATION[key]}
        value={values[key]}
      >
        {values[key]}
      </MedcertTableRow>
    ))}
  </table>
);

export default HospitalInfoTable;
