import React from "react";
import classNames from "classnames";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import MedcertTableRow from "./MedcertTableRow";
import Panel from "../../Templates/Forms/Panel";

import styles from "../medcerts.module.scss";

const camel2title = camelCase =>
  camelCase
    .replace(/([A-Z])/g, match => ` ${match}`)
    .replace(/^./, match => match.toUpperCase());

const CustomFieldsTable = ({ values }) => (
  <Panel heading="Additional Information" icon={faInfoCircle}>
    <table className={classNames("table is-fullwidth", styles.isHalfColumn)}>
      {Object.keys(values).map(key => (
        <MedcertTableRow
          index={key}
          header={camel2title(key)}
          value={values[key]}
        >
          {values[key]}
        </MedcertTableRow>
      ))}
    </table>
  </Panel>
);

export default CustomFieldsTable;
