import React, { Fragment, useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserTie,
  faEnvelope,
  faCheckDouble,
  faHospital,
  faNotesMedical,
} from "@fortawesome/free-solid-svg-icons";
import AppContext from "../../../context/AppContext";
import api from "../../../services/api";

import MedcertEmailModal from "../MedcertForm/MedcertEmailModal";
import Panel from "../../Templates/Forms/Panel";
import UpdateMedcertStatus from "../ViewMedcert/UpdateMedcertStatus";
import MedcertStatusTable from "../ViewMedcert/MedcertStatusTable";
import UpdateCustomFields from "../ViewMedcert/UpdateCustomFields";
import CustomFieldsTable from "../ViewMedcert/CustomFieldsTable";
import UpdateHospitalInformation from "../ViewMedcert/UpdateHospitalInformation";
import HospitalInfoTable from "../ViewMedcert/HospitalInfoTable";
import MedcertInfoTable from "../ViewMedcert/MedcertInfoTable";

const ViewMedcert = ({ isAdmin }) => {
  const appContext = useContext(AppContext);
  let { activeMedcert, useToast, setActiveMedcert } = appContext;
  if (!activeMedcert)
    activeMedcert = { dateIssued: "", employeeName: "", status: "" };
  const [receivedBy, setReceivedBy] = useState("");
  const [customFields, setCustomFields] = useState([]);
  const [clientCode, setClientCode] = useState([]);

  useEffect(() => {
    api.get(`staff/?id=${activeMedcert.receivedBy}`).then(response => {
      const results = response.data.results[0];
      const nurseName = `${results.firstName} ${results.lastName} (${
        results.staffType
      })`;
      setReceivedBy(nurseName);
    });
    api
      .post(
        "profiles/fetch_individual/",
        {
          id: activeMedcert.supervisor,
        },
        {
          headers: {
            Authorization: `${sessionStorage.getItem("loginToken")}`,
          },
        }
      )
      .then(response => {
        appContext.setActiveMedcert({
          ...activeMedcert,
          email: response.data.email,
        });
      });
    let tempClientCode = sessionStorage.getItem("clientCode");
    setClientCode(tempClientCode);
    api
      .get(`custom-fields/?client=${clientCode}&model=medcert`)
      .then(response => {
        setCustomFields(response.data);
      });
  }, []);

  const updateHospital = values => {
    api
      .put(`medcerts/${values.id}/`, values)
      .then(response => {
        appContext.setActiveMedcert(values);
        useToast(
          "Successfully updated hospital information.",
          "is-success",
          5000
        );
      })
      .catch(error => useToast("Something went wrong.", "is-danger", 5000));
  };

  return (
    <Fragment>
      <div className="columns">
        <div className="column">
          <div className="tags has-addons are-medium has-text-weight-normal mb-1">
            <span className="tag">
              <FontAwesomeIcon icon={faUserTie} className="mr-1" />
              <b>Employee</b>
            </span>
            <span className="tag is-light">
              <span>{activeMedcert.employeeName}</span>
            </span>
          </div>
        </div>

        <div className="column">
          <div className="buttons is-right">
            <button
              className="button"
              type="button"
              onClick={() => {
                appContext.useModal(
                  <MedcertEmailModal
                    data={activeMedcert}
                    receivedBy={receivedBy}
                  />,
                  "primary",
                  "GENERATE VALID MEDCERT EMAIL"
                );
              }}
              disabled={
                !(
                  activeMedcert.status === "Valid" ||
                  activeMedcert.status === "Unable to Verify" ||
                  isAdmin
                )
              }
              title="Email templates are for VALID and UNABLE TO VERIFY medcerts only"
            >
              <span className="icon">
                <FontAwesomeIcon icon={faEnvelope} />
              </span>{" "}
              <span>Generate emails</span>
            </button>
          </div>
        </div>
      </div>

      <Panel heading={`Status: ${activeMedcert.status}`} icon={faCheckDouble}>
        {activeMedcert.status !== "Valid" && !isAdmin ? (
          <UpdateMedcertStatus activeMedcert={activeMedcert} />
        ) : (
          <MedcertStatusTable values={activeMedcert} />
        )}
      </Panel>
      {customFields.length > 0 &&
        (activeMedcert.status !== "Valid" && !isAdmin ? (
          <UpdateCustomFields
            customFields={customFields}
            customFieldValues={activeMedcert.customFields}
            medcertValues={activeMedcert}
            useToast={useToast}
            setActiveMedcert={setActiveMedcert}
          />
        ) : (
          Object.keys(activeMedcert.customFields).length > 0 && (
            <CustomFieldsTable values={activeMedcert.customFields} />
          )
        ))}
      <Panel heading="Hospital Information" icon={faHospital}>
        {activeMedcert.status !== "Valid" && !isAdmin ? (
          <UpdateHospitalInformation
            activeMedcert={activeMedcert}
            updateHospital={updateHospital}
          />
        ) : (
          <HospitalInfoTable values={activeMedcert} />
        )}
      </Panel>
      <Panel heading="Medical Certificate Information" icon={faNotesMedical}>
        <MedcertInfoTable values={activeMedcert} receivedBy={receivedBy} />
      </Panel>
    </Fragment>
  );
};

export default ViewMedcert;
