import React, { useState, useContext, Fragment } from "react";
import classNames from "classnames";

import AppContext from "../../../context/AppContext";
import api from "../../../services/api";
// import Input from "../../Templates/Forms/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faThumbsUp,
  faHourglassHalf,
  faThumbsDown,
  faBan,
} from "@fortawesome/free-solid-svg-icons";

const STATUS_LIST = ["Pending", "Valid", "Not Valid", "Unable to Verify"];

const UpdateStatusForm = ({ activeMedcert }) => {
  const [isActive, setActivebutton] = useState(activeMedcert.status);
  const [reasonForStatus, setReasonForStatus] = useState(activeMedcert.notes);
  const [isLoading, setLoadingIndicator] = useState(false);
  const [indicator, setIndicator] = useState("");
  const appContext = useContext(AppContext);

  const updateStatus = () => {
    setLoadingIndicator(true);
    let newMedcert = activeMedcert;
    newMedcert.status = isActive;
    newMedcert.notes = reasonForStatus;

    const Indicator = ({ type }) => (
      <button className="button is-white mt-2">
        <FontAwesomeIcon
          icon={type === "success" ? faCheckCircle : faTimesCircle}
          className={`mr-1 has-text-${type}`}
        />
        <span className={`has-text-weight-bold has-text-${type}`}>
          {type === "success"
            ? "Saved!"
            : "There was an error, please try again."}
        </span>
      </button>
    );

    api
      .put(`medcerts/${newMedcert.id}/`, newMedcert)
      .then(response => {
        appContext.setActiveMedcert(newMedcert);
        setLoadingIndicator(false);
        setIndicator(<Indicator type="success" />);
        setTimeout(() => setIndicator(""), 3000);
      })
      .catch(error => {
        setLoadingIndicator(false);
        setIndicator(<Indicator type="danger" />);
        setTimeout(() => setIndicator(""), 3000);
      });
  };

  const getIcon = status => {
    switch (status) {
      case "Pending":
        return faHourglassHalf;
      case "Valid":
        return faThumbsUp;
      case "Not Valid":
        return faThumbsDown;
      case "Unable to Verify":
        return faBan;
      default:
        return;
    }
  };

  return (
    <Fragment>
      {activeMedcert.status !== "Valid" && (
        <div className="columns">
          <div className="column is-narrow">
            <p className="mb-1">
              <b>Update Status</b>
            </p>
            <div className="field has-addons">
              {STATUS_LIST.map((status, index) => (
                <p className="control">
                  <button
                    className={classNames("button", {
                      // You color it and make it bold only if it's active - it really is repetitive :-)
                      "is-warning has-text-weight-bold":
                        isActive === "Pending" && isActive === status,
                      "is-primary has-text-weight-bold":
                        isActive === "Valid" && isActive === status,
                      "is-light has-text-weight-bold":
                        isActive === "Not Valid" && isActive === status,
                      "is-danger has-text-weight-bold":
                        isActive === "Unable to Verify" && isActive === status,
                    })}
                    key={index}
                    type="button"
                    onClick={() => setActivebutton(status)}
                    // disabled={index === 0}
                  >
                    <span className="icon">
                      <FontAwesomeIcon icon={getIcon(status)} />
                    </span>
                    <span>{status}</span>
                  </button>
                </p>
              ))}
            </div>
            {/* </div>
          <div className="column"> */}
            <p className="control mt-2 mb-1">
              <label className="has-text-weight-bold">Reason for Status</label>
            </p>
            <p className="control">
              <input
                className="input"
                value={reasonForStatus}
                onChange={event => setReasonForStatus(event.target.value)}
              />
            </p>
            <div className="field is-grouped">
              <p className="control">
                <button
                  type="button"
                  className={classNames("button is-primary mt-2", {
                    "is-loading": isLoading === true,
                  })}
                  onClick={updateStatus}
                  disabled={
                    isActive === activeMedcert.status &&
                    reasonForStatus === activeMedcert.notes
                  }
                >
                  Save Changes
                </button>
              </p>
              <p className="control">{indicator}</p>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default UpdateStatusForm;
