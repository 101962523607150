import React from 'react'
import Input from '../../../components/Templates/Forms/Input';
import SearchBar from '../../../components/Templates/Forms/SearchBar';

const HospitalInformation = ({formikHelpers}) => (
  <>
    <div className='columns'>
      <div className='column pb-0'>
        <Input
          isRequired
          name='hospitalName'
          label='Hospital/Clinic Name'
          placeholder="Search hospitals/clinics here..."
          render={({ field, form, props }) => {
            return (
              <SearchBar
                id='searchHospital'
                endpoint="medcerts/hospitals"
                resultDisplay={["hospitalName"]}
                field={field}
                form={form}
                props={props}
                isAutocomplete={true}
                callbackFunction={result => {
                  formikHelpers.setFieldValue("hospitalStreetAddress", result.hospitalStreetAddress || '')
                  formikHelpers.setFieldValue("hospitalBarangay", result.hospitalBarangay || '')
                  formikHelpers.setFieldValue("hospitalCityMunicipality", result.hospitalCityMunicipality || '')
                  formikHelpers.setFieldValue("hospitalProvince", result.hospitalProvince || '')
                  formikHelpers.setFieldValue("hospitalPostalCode", result.hospitalPostalCode || '')
                  formikHelpers.setFieldValue("hospitalContactNumber", result.hospitalContactNumber || '')

                  return formikHelpers.values
                }}
              />
            )
          }}
        />
      </div>
    </div>
    <div className='columns'>
      <div className='column py-0'>
        <Input type='text' name='hospitalStreetAddress' label='Street Address' value={formikHelpers.values.hospitalStreetAddress}/>
      </div>
      <div className='column py-0'>
        <Input type='text' name='hospitalBarangay' label='Barangay' value={formikHelpers.values.hospitalBarangay}/>
      </div>
      <div className='column py-0'>
        <Input type='text' name='hospitalCityMunicipality' label='City/Municipality' value={formikHelpers.values.hospitalCityMunicipality}/>
      </div>
    </div>
    <div className='columns'>
      <div className='column py-0'>
        <Input type='text' name='hospitalProvince' label='Province' value={formikHelpers.values.hospitalProvince}/>
      </div>
      <div className='column py-0'>
        <Input type='text' name='hospitalContactNumber' label='Contact Number/Email Address' value={formikHelpers.values.hospitalContactNumber}/>
      </div>
      <div className='column py-0'>
        <Input type='text' name='hospitalPostalCode' label='Postal Code' value={formikHelpers.values.hospitalPostalCode}/>
      </div>
    </div>
  </>
)

export default HospitalInformation