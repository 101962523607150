import React from "react";
import { Formik, Form } from "formik";
import { decamelizeKeys } from "humps";
import { isEqual } from "lodash";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import CustomField from "../../Templates/CustomField/CustomField";
import Panel from "../../Templates/Forms/Panel";
import api from "../../../services/api";

const UpdateCustomFields = ({
  customFields,
  customFieldValues,
  medcertValues,
  useToast,
  setActiveMedcert,
}) => {
  const updateCustomFieldValues = values => {
    api
      .put(`medcerts/${medcertValues.id}/`, {
        ...medcertValues,
        customFields: values,
      })
      .then(response => {
        setActiveMedcert(response.data);
        useToast(
          "Successfully updated additional information.",
          "is-success",
          5000
        );
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Panel heading="Additional Information" icon={faInfoCircle}>
      <Formik
        initialValues={decamelizeKeys(customFieldValues)}
        onSubmit={updateCustomFieldValues}
      >
        {formikHelpers => {
          return (
            <Form>
              {customFields.map((item, index) => (
                <CustomField
                  key={index}
                  customField={item}
                  emptyFields={[]}
                  values={formikHelpers.values}
                  hideAdditionalNotes
                />
              ))}
              <button
                type="submit"
                className="button is-primary is-pulled-right"
                disabled={isEqual(
                  formikHelpers.values,
                  decamelizeKeys(customFieldValues)
                )}
              >
                Update Additional Information
              </button>
            </Form>
          );
        }}
      </Formik>
    </Panel>
  );
};

export default UpdateCustomFields;
