import React, { Fragment, useState, useEffect, useContext } from "react";
import moment from "moment";
import { Formik, Form } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

import EmailForm from "../../Templates/Forms/EmailForm";
import Input from "../../Templates/Forms/Input";
import SearchBar from "../../Templates/Forms/SearchBar";
import AppContext from "../../../context/AppContext";

const MedcertEmailModal = ({ data, receivedBy }) => {
  const appContext = useContext(AppContext);
  const [emailBody, setEmailBody] = useState("");
  const [supervisor, setSupervisor] = useState("");
  const [isSearching, setIsSearching] = useState(true);

  const getEmailBody = data => {
    let message = "Good day,";

    switch (data.status) {
      case "Valid":
        message += `\n\nPlease be informed that the medical certificate of ${data.employeeName ||
          ""} has been validated. See details below:`;

        message += `\n\nDate of Medical Certificate: ${moment(
          data.dateIssued
        ).format("MMMM D, YYYY")}`;

        message += `\n\nDate Medical Certificate Received by Clinic: ${moment(
          data.dateReceived
        ).format("MMMM D, YYYY")}`;

        message += `\n\nRequest for Validation Received by: ${receivedBy}`;

        message += `\n\nRecommendation: ${
          !!data.returnBy
            ? `Return by ${data.returnBy}`
            : `${data.numberOfLeaves || `x`} days leave`
        }`;

        message += `\n\nDate Validated: ${moment().format("MMMM D, YYYY")}`;

        message += `\n\nTime Validated: ${moment().format("HH:mm A")}`;

        message += `\n\nValidated by: ${sessionStorage.getItem("staffName")}`;

        message += `\n\nResult of Validation: The submitted medical certificate is considered true and valid.`;
        break;

      case "Unable to Verify":
        message += `\n\nPlease be informed that the clinic is unable to verify the medical certificate of ${data.employeeName ||
          ""}. See attempts to validate below:`;

        message += `${
          !!data.notes ? `\n\nReason for Status: ${data.notes}` : ""
        }`;
        message += `${
          !!data.customFields.attemptsToValidate
            ? `\nAttempts to Validate: ${data.customFields.attemptsToValidate}`
            : ""
        }`;

        message += `\n\nDate Medical Certificate Received by Clinic: ${moment(
          data.dateReceived
        ).format("MMMM D, YYYY")}`;

        message += `\n\nRequest for Validation Received by: ${receivedBy}`;

        message += `\n\nRecommendation: ${
          !!data.returnBy
            ? `Return by ${data.returnBy}`
            : `${data.numberOfLeaves || `x`} days leave`
        }`;
        message += `\n\nResult of Validation: The clinic is unable to verify the medical certificate. Please advise next steps.`;
        break;

      default:
        break;
    }

    message += `\n\nNote: The clinic can only share limited information to safeguard data privacy.`;
    return message;
  };

  const handleKeyDown = event => {
    if (event.key === "Enter") event.preventDefault();
  };

  useEffect(() => {
    if (!!data) setEmailBody(getEmailBody(data));
  }, [data, supervisor]);

  const SearchSupervisor = () => {
    return (
      <Formik>
        <Form autoComplete="off" onKeyDown={handleKeyDown}>
          <Input
            isRequired
            name="supervisorName"
            placeholder="Search for supervisor..."
            render={({ field, form, props }) => {
              return (
                <SearchBar
                  id="searchSupervisor"
                  endpoint="consultations/employees"
                  resultDisplay={["firstName", "lastName"]}
                  additionalParams={`site_id=${appContext.siteDetails.id}`}
                  field={field}
                  form={form}
                  props={props}
                  callbackFunction={result => {
                    setSupervisor(result);
                    setIsSearching(false);
                    return result;
                  }}
                />
              );
            }}
          />
        </Form>
      </Formik>
    );
  };

  const HasSupervisor = () => (
    <Fragment>
      <p className="control">
        <input
          className="input is-static"
          value={`${supervisor.firstName || ""} ${supervisor.lastName || ""}`}
          readOnly
        />
      </p>
      <p className="control">
        <div className="buttons">
          <button
            type="button"
            className="button is-primary"
            onClick={() => setIsSearching(true)}
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
          <button
            type="button"
            className="button is-danger is-outlined"
            onClick={() => {
              setSupervisor("");
              setIsSearching(true);
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      </p>
    </Fragment>
  );

  return (
    <Fragment>
      <EmailForm
        subject={`Medical Certificate Validation for Employee ${data.employeeName ||
          ""}`}
        to={isSearching ? <SearchSupervisor /> : <HasSupervisor />}
        toEmail={supervisor.email || "No email found"}
        emailBody={emailBody}
        closeModal={() => appContext.closeModal()}
      />
    </Fragment>
  );
};

export default MedcertEmailModal;
