export const HOSPITAL_INFORMATION = {
  hospitalName: "Hospital/Clinic Name",
  hospitalContactNumber: "Contact Number",
  hospitalStreetAddress: "Street Address",
  hospitalBarangay: "Barangay",
  hospitalCityMunicipality: "City/Municipality",
  hospitalProvince: "Province",
  hospitalPostalCode: "Postal Code",
};

export const MEDCERT_INFORMATION = {
  dateIssued: "Date Issued",
  dateReceived: "Date Received",
  receivedBy: "Received By",
  purpose: "Purpose",
  attendingPhysician: "Attending Physician",
  diagnosis: "Diagnosis",
  returnBy: "Return By",
  numberOfLeaves: "Recommended Number of Leaves",
};

export const MEDCERT_STATUS = {
  status: "Status",
  notes: "Other Notes",
};
