// AS OF CHECKING 20200505 - This view is a dead one (no active links directing to this page)

import React, { useContext } from "react";
import Layout from "../../layout/Layout";
import MedcertTitle from "../../components/Medcerts/ViewMedcert/MedcertTitle";
import SEO from "../../layout/Seo";
import ViewMedcert from "../../components/Medcerts/ViewMedcert/ViewMedcert";
import AppContext from "../../context/AppContext";

export default () => {
  const appContext = useContext(AppContext);
  let { activeMedcert } = appContext;

  return (
    <Layout pageTitle={<MedcertTitle activeMedcert={activeMedcert} />}>
      <SEO title="View Medcert" />
      <ViewMedcert />
    </Layout>
  );
};
