import React from 'react'
import { Formik, Form } from 'formik';
import HospitalInformation from '../MedcertForm/HospitalInformation';
import { isEqual } from 'lodash';

const UpdateHospitalInformation = ({activeMedcert, updateHospital}) => (
  <Formik initialValues={activeMedcert} onSubmit={updateHospital}>
    {formikHelpers => (
    <Form>
      <HospitalInformation formikHelpers={formikHelpers} />
      {!isEqual(formikHelpers.values, activeMedcert)
        &&
      <button type="submit" className="button is-primary is-pulled-right">
        Update Hospital Information
      </button>
      }
    </Form>
    )}
  </Formik>
)

export default UpdateHospitalInformation